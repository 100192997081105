body {
  margin: 0;
  font-family: 'Raleway','roboto' , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg-color: #EFEFEF;
  --accent-dark: #03367B;
  --accent-mid: #005FB8;
  --accent-light: #00AED4;
  --main-dark: #2C363F;
}
